import { Carousel, Modal, Row } from 'antd';
import { config } from 'config/config';
import Router from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { PageDetailEnum } from 'src/shared/enums';
import { IContentComponent, IContentDetail } from 'src/shared/models';

interface IImageCarousel {
  component: IContentComponent;
}

export const ImageCarousel: FC<IImageCarousel> = ({ component }) => {
  const { componentDetails } = component;
  const settings = {
    dots: { className: 'imageCarousel__dots' },
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const renderItem = (detail: IContentDetail, key: number) => {
    switch (detail.type) {
      case PageDetailEnum.VIDEO:
        return <YoutubeVideo {...{ detail, key }} />;
      case PageDetailEnum.IMAGE:
        return <CustomImageCarousel {...{ detail, key }} />;
    }
  };

  return (
    <div className="container--no-padding">
      <Carousel className="imageCarousel" {...settings}>
        {componentDetails
          .filter((detail) => detail.status)
          .sort((a, b) => a.order - b.order)
          .map((detail: IContentDetail, i: number) => renderItem(detail, i))}
      </Carousel>
    </div>
  );
};

interface IItemCarousel {
  detail: IContentDetail;
  key: number;
}

const CustomImageCarousel: FC<IItemCarousel> = ({ detail, key }) => {
  const navigateTo = (url: string) => {
    if (url) Router.push(url, undefined, { shallow: true });
  };

  return (
    <Row
      id="container-image-home-page"
      className={detail.slug ? 'imageCarousel__row' : ''}
      onClick={() => navigateTo(detail.slug)}
      key={key}
    >
      <img
        loading="eager"
        alt={'Carrousel image ' + key}
        className="imageCarousel__media imageCarousel__mobile"
        src={detail.valueMobile}
      />
      <img
        loading="eager"
        alt={'Carrousel image ' + key}
        className="imageCarousel__media imageCarousel__desktop"
        src={detail.value}
      />
    </Row>
  );
};

const YoutubeVideo: FC<IItemCarousel> = ({ detail, key }) => {
  const [height, setHeight] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [width, setWidth] = useState<number>();

  const destroyModal = () => {
    setIsModalVisible(false);
  };

  const showItemModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const handleResize = () => {
      const offsetHeight: number = document.getElementById(
        'container-image-home-page'
      )?.offsetHeight;

      const offsetWidth: number = document.getElementById(
        'container-image-home-page'
      )?.offsetWidth;

      setHeight(offsetHeight);
      setWidth(offsetWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isAutoplayActive = detail.position === 1;
  const videoOffset = isAutoplayActive ? 120 : 0;
  const _config =
    'controls=0&disablekb=1&playsinline=1&color=white&mute=1&rel=0';
  const videoURL = `${config.APP.YOUTUBE_EMBEDDED_URL}${detail.value}?${_config}`;

  return (
    <>
      <Row
        id="container-row-id"
        className="container-row"
        justify="center"
        align="middle"
        onClick={showItemModal}
        style={{
          height,
          width,
          cursor: 'pointer',
        }}
      >
        {isAutoplayActive && (
          <img className="icon icon__white icon__size-100 icon--player" />
        )}
        <iframe
          className={`youtube-iframe ${
            isAutoplayActive && 'youtube-iframe__autoplay'
          }`}
          key={key}
          width={width}
          height={height + videoOffset}
          src={
            isAutoplayActive
              ? `${videoURL}&loop=1&autoplay=1&playlist=${detail.value}`
              : videoURL
          }
        />
      </Row>

      {isModalVisible && (
        <Modal
          visible={isModalVisible}
          className="iframeyt-modal"
          centered
          footer={null}
          onCancel={destroyModal}
          destroyOnClose
        >
          <iframe width={'100%'} height={'100%'} src={videoURL} />
        </Modal>
      )}
    </>
  );
};
