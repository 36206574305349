import { Row } from 'antd';
import React from 'react';
import { getComponentHeader } from '.';
import { PageDetailEnum } from '../../shared/enums';
import { IContentComponent, IContentDetail } from '../../shared/models';
import { getTranslatedData } from 'utils/intlUtils';

const { TEXT } = PageDetailEnum;

interface Props {
  component: IContentComponent;
}

export const Text = ({ component }: Props) => {
  const { componentDetails, icon, title } = component;

  let text: IContentDetail;

  componentDetails
    .filter((detail) => detail.status)
    .forEach((detail) => {
      if (detail.type === TEXT) text = detail;
    });

  return (
    <div className="container block cardImage">
      <Row
        justify="center"
        align="middle"
        className="container--no-padding block"
      >
        {getComponentHeader({
          icon,
          title: getTranslatedData(component, 'content') || title,
        })}
      </Row>
      <Row justify="center" align="middle" className="container block">
        {text && text.value && (
          <Row justify="center" className="regular-body">
            {getTranslatedData(text, 'content') || text.value}
          </Row>
        )}
      </Row>
    </div>
  );
};
