export const YumaIconsFaqs = new Map<number, string>([
  [0, 'None'],
  [1, 'eLearning'],
  [2, 'EventDigital'],
  [3, 'ShoppingCart'],
  [4, 'Document'],
  [5, 'Gift'],
  [6, 'Location'],
  [7, 'Reader'],
]);
