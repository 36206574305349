import { Row } from 'antd';
import React from 'react';
import {
  Banner,
  ConnectedCarousel,
  FAQComponent,
  ImageCarousel,
  LinkedImage,
  Text,
  WYSComponent,
} from 'src/components/staticComponents';
import { PageComponentEnum } from 'src/shared/enums';
import { IContentComponent, IContentPage } from 'src/shared/models';
import { ConnectedRedeemPointsCarousel } from './RedeemPointsCarrousel';

const {
  BANNER,
  CAROUSEL,
  LINKED_IMAGE,
  TEXT,
  WYS,
  REDEEM_POINTS,
  FORMATION,
  TALK,
  CAMPAIGN,
  FAQ,
} = PageComponentEnum;

export const StaticPage = (contentPage: IContentPage) => {
  const { pageComponents } = contentPage;

  const renderComponent = (component: IContentComponent, index: number) => {
    const { type } = component;
    let element: JSX.Element;

    switch (type) {
      case BANNER:
        element = <Banner component={component} />;
        break;
      case CAROUSEL:
        element = <ImageCarousel component={component} />;
        break;
      case TEXT:
        element = <Text component={component} />;
        break;
      case LINKED_IMAGE:
        element = <LinkedImage component={component} />;
        break;
      case WYS:
        element = <WYSComponent component={component} />;
        break;
      case REDEEM_POINTS:
        element = <ConnectedRedeemPointsCarousel {...{ component }} />;
        break;
      case CAMPAIGN:
      case FORMATION:
      case TALK:
        element = <ConnectedCarousel {...{ component }} />;
        break;
      case FAQ:
        element = <FAQComponent {...{ component, pageComponents }} />;
    }

    if (element)
      return (
        <Row className="block" key={index}>
          {element}
        </Row>
      );
  };

  return (
    <>
      {pageComponents &&
        pageComponents
          .sort((a, b) => a.order - b.order)
          .filter((component) => component.status)
          .map((component: IContentComponent, i: number) =>
            renderComponent(component, i)
          )}
    </>
  );
};
