import React, { FC, useEffect, useState } from 'react';
import { IContentComponent } from '../../shared/models';
import {
  IconProps,
  YuAccordion,
  YuCol,
  YuFlex,
  YuIcon,
  YuParagraph,
  YuRow,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import { YumaIconsFaqs } from 'src/shared/enums/yumaIcons.enum';
import ConditionalRenderer from '../ConditionalRenderer';
import _ from 'lodash';
import { PageComponentEnum } from 'src/shared/enums';

interface IFaq {
  component: IContentComponent;
  pageComponents: IContentComponent[];
}

export const FAQComponent: FC<IFaq> = ({ component, pageComponents }) => {
  const [faqsList, setFaqsList] = useState([]);
  const noIconFaqs = YumaIconsFaqs.get(0);
  const isIconHidden: boolean =
    YumaIconsFaqs.get(component.icon) === noIconFaqs;
  const isFirstComponentFAQ =
    component.order === 0 && pageComponents[0].type === PageComponentEnum.FAQ;

  const renderSections = () => {
    const sortedFaqs = _.sortBy(faqsList, ['order']);

    return _.map(sortedFaqs, (faq) => ({
      label: (
        <YuParagraph fw="bold" size={{ XS: 'L', S: 'XL' }}>
          {faq.valueMobile}
        </YuParagraph>
      ),
      content: (
        <YuParagraph
          style={{ marginBottom: 0 }}
          dangerouslySetInnerHTML={{ __html: faq.value }}
          size={'L'}
        ></YuParagraph>
      ),
    }));
  };

  const hideAccordionHeader = () => {
    return (
      YumaIconsFaqs.get(component.icon) === noIconFaqs && component.title === ''
    );
  };

  const showAccordionHeaderSpacer = () => {
    return (
      YumaIconsFaqs.get(component.icon) !== noIconFaqs || component.title !== ''
    );
  };

  useEffect(() => {
    setFaqsList(component.componentDetails);
  }, []);

  return (
    <YuRow justify="center">
      <ConditionalRenderer
        condition={isFirstComponentFAQ}
        trueComponent={<YuSpacer size="L" />}
      />
      <YuCol xs={12} m={9} xl={6}>
        <ConditionalRenderer
          condition={component.componentDetails.length !== 0}
          trueComponent={
            <>
              <YuFlex
                gap={'XS'}
                align={'center'}
                hidden={hideAccordionHeader()}
              >
                <YuIcon
                  name={YumaIconsFaqs.get(component.icon) as IconProps['name']}
                  size="XL"
                  hidden={isIconHidden}
                ></YuIcon>
                <YuTitle size={'XS'}>{component.title}</YuTitle>
              </YuFlex>
              <ConditionalRenderer
                condition={showAccordionHeaderSpacer()}
                trueComponent={<YuSpacer size="XS" />}
              ></ConditionalRenderer>
              <YuAccordion sections={renderSections()} />
              <YuSpacer size="M" />
            </>
          }
        ></ConditionalRenderer>
      </YuCol>
    </YuRow>
  );
};
