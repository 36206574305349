import routePaths from 'config/routes';
import { builderChallenge } from 'src/components/challenge/builder';
import { PageComponentEnum, PageDetailEnum } from 'src/shared/enums';
import {
  ChallengeDetail,
  IContentComponent,
  IContentDetail,
  IUser,
} from 'src/shared/models';

interface IChallengeCarousel {
  challenges: ChallengeDetail[];
  classByType: string;
  description: IContentDetail;
  hasDescription: boolean;
  hrefPath: string;
  icon: number;
  idContentComponent: number;
  title: string;
}

class ChallengeCarouselBuilder {
  public build(
    contentComponent: IContentComponent,
    user: IUser | null
  ): IChallengeCarousel {
    let challengeCarousel: IChallengeCarousel;
    const { componentDetails, icon, title, idContentComponent, type } =
      contentComponent;
    const challenges: ChallengeDetail[] = [];

    componentDetails
      .filter(({ status }) => status)
      .forEach((componentDetail) => {
        switch (componentDetail.type) {
          case PageDetailEnum.TEXT:
            challengeCarousel = {
              ...challengeCarousel,
              description: componentDetail,
              hasDescription: Boolean(componentDetail.value?.trim()),
            };
            break;
          case PageDetailEnum.LINK:
            if (!componentDetail.challenge) break;
            challenges.push(builderChallenge(componentDetail.challenge, user));
            break;
        }
      });

    return {
      ...challengeCarousel,
      title,
      icon,
      classByType: getChallengePageClass(type),
      hrefPath: getChallengeListPath(type),
      idContentComponent,
      challenges,
    };
  }
}

export const BuilderChallengeCarousel = (
  resourceList: IContentComponent,
  user: IUser | null
): IChallengeCarousel => {
  const builder = new ChallengeCarouselBuilder();

  return builder.build(resourceList, user);
};

const getChallengeListPath = (challengeType: PageComponentEnum): string => {
  switch (challengeType) {
    case PageComponentEnum.FORMATION:
      return routePaths.PAGES.TRAINING;
    case PageComponentEnum.TALK:
      return routePaths.PAGES.TALK;
    case PageComponentEnum.CAMPAIGN:
      return routePaths.PAGES.CAMPAIGN;
    default:
      return routePaths.INDEX;
  }
};

const getChallengePageClass = (challengeType: PageComponentEnum): string => {
  switch (challengeType) {
    case PageComponentEnum.CAMPAIGN:
      return 'campaignsPage challengePage';
    default:
      return 'challengePage';
  }
};
